var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchCustomer.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Chercher client par numéro de téléphone","label-for":"phone-input","state":_vm.phoneNumber.length === 8 ? true : false,"invalid-feedback":_vm.phoneNumber.length < 8 ? 'Le numéro de téléphone doit contenir 8 chiffres' : ''}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"tel","placeholder":"Entrez le numéro de téléphone","maxlength":"8","minlength":"8"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phoneNumber"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary","type":"submit","disabled":_vm.phoneNumber.length !== 8}},[_c('b-icon',{attrs:{"icon":"search"}}),_vm._v(" Chercher client ")],1)],1)],1)],1)],1),_c('b-card-code',{attrs:{"title":"Créer un nouveau colis"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de colis")])]),(_vm.isLoading === true)?_c('div',{staticClass:"text-center mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nom du client","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom"},model:{value:(_vm.order.name),callback:function ($$v) {_vm.$set(_vm.order, "name", $$v)},expression:"order.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3499414456)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Gouvernorat","label-for":"gouvernorat"}},[_c('validation-provider',{attrs:{"name":"gouvernorat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Choisissez une gouvernorat","label":"name","options":_vm.governorates},on:{"input":function($event){return _vm.getDelegations()}},model:{value:(_vm.order.governorate),callback:function ($$v) {_vm.$set(_vm.order, "governorate", $$v)},expression:"order.governorate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2831417517)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Délégation","label-for":"delegation"}},[_c('validation-provider',{attrs:{"name":"delegation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Choisissez une délégation","label":"name","options":_vm.delegations,"disabled":!_vm.order.governorate},on:{"input":function($event){return _vm.getLocalities()}},model:{value:(_vm.order.delegation),callback:function ($$v) {_vm.$set(_vm.order, "delegation", $$v)},expression:"order.delegation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1678945734)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Localité","label-for":"localité"}},[_c('validation-provider',{attrs:{"name":"localité"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Choisissez une localité","label":"name","options":_vm.localities,"disabled":!_vm.order.governorate || !_vm.order.delegation},model:{value:(_vm.order.locality),callback:function ($$v) {_vm.$set(_vm.order, "locality", $$v)},expression:"order.locality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3196601129)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Adresse complète","label-for":"adresse"}},[_c('validation-provider',{attrs:{"name":"adresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Adresse complètes"},model:{value:(_vm.order.address),callback:function ($$v) {_vm.$set(_vm.order, "address", $$v)},expression:"order.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3728532997)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"Téléphone"}},[_c('validation-provider',{attrs:{"name":"Téléphone","rules":"required|integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone","maxlength":"8","type":"tel","formatter":_vm.formatPhone},model:{value:(_vm.order.phone1),callback:function ($$v) {_vm.$set(_vm.order, "phone1", $$v)},expression:"order.phone1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,857923526)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Téléphone 2","label-for":"Téléphone2"}},[_c('validation-provider',{attrs:{"name":"Telephone2","rules":"integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone 2","maxlength":"8","type":"tel","formatter":_vm.formatPhone},model:{value:(_vm.order.phone2),callback:function ($$v) {_vm.$set(_vm.order, "phone2", $$v)},expression:"order.phone2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4137939351)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Montant total inclut la livraison","label-for":"montant ttc"}},[_c('validation-provider',{attrs:{"name":"montant ttc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Montant TTC","type":"number","step":"1"},model:{value:(_vm.order.price_ttc),callback:function ($$v) {_vm.$set(_vm.order, "price_ttc", $$v)},expression:"order.price_ttc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,879698458)})],1)],1),(_vm.user.weight_price === true)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Poids volumétrique de colis","label-for":"Poids volumétrique de colis"}},[_c('validation-provider',{attrs:{"name":"Poids de colis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Poids volumétrique de colis","label":"weight","options":_vm.user.weights},on:{"input":_vm.fetch_price},model:{value:(_vm.order.weight),callback:function ($$v) {_vm.$set(_vm.order, "weight", $$v)},expression:"order.weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1371363510)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Mode de paiement","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Mode de paiement","label":"value","options":_vm.types,"reduce":function (type) { return type.key; }},model:{value:(_vm.order.payment_method),callback:function ($$v) {_vm.$set(_vm.order, "payment_method", $$v)},expression:"order.payment_method"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3335417954)})],1)],1),_c('b-col',{staticClass:"demo-inline-spacing",attrs:{"md":"4"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.order.is_an_exchange),callback:function ($$v) {_vm.$set(_vm.order, "is_an_exchange", $$v)},expression:"order.is_an_exchange"}},[_vm._v(" Échange ")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.order.is_fragile),callback:function ($$v) {_vm.$set(_vm.order, "is_fragile", $$v)},expression:"order.is_fragile"}},[_vm._v(" Fragile ")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.order.is_discharge),callback:function ($$v) {_vm.$set(_vm.order, "is_discharge", $$v)},expression:"order.is_discharge"}},[_vm._v(" Décharge ")])],1)],1),_c('br'),(_vm.order.is_an_exchange)?_c('b-col',[_c('b-form-group',{attrs:{"label":"Article à échanger","label-for":"exchange_article"}},[_c('validation-provider',{attrs:{"name":"exchange_article"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"exchange_article","placeholder":"Article à échanger"},model:{value:(_vm.order.exchange_article),callback:function ($$v) {_vm.$set(_vm.order, "exchange_article", $$v)},expression:"order.exchange_article"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2220737284)})],1)],1):_vm._e(),(_vm.order.is_an_exchange)?_c('b-col',[_c('b-form-group',{attrs:{"label":"Quantité à échanger","label-for":"exchange_quantity"}},[_c('validation-provider',{attrs:{"name":"exchange_quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"exchange_quantity","placeholder":"Quantité à échanger"},model:{value:(_vm.order.exchange_quantity),callback:function ($$v) {_vm.$set(_vm.order, "exchange_quantity", $$v)},expression:"order.exchange_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3231966379)})],1)],1):_vm._e(),_c('b-card-code',{attrs:{"title":"Produits"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées des produits ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain.apply(null, arguments)}}},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Produit","label-for":"produit"}},[_c('validation-provider',{attrs:{"name":"products","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"produit"},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Quantité","label-for":"quantity"}},[_c('b-form-input',{attrs:{"id":"quantity","type":"number","placeholder":"Quantity"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Supprimer")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-2",style:({
                  backgroundColor: '#F4E7E7 !important',
                  borderColor: '#F4E7E7 !important',
                  color: '#ea5455 !important'
                }),attrs:{"variant":"outline-secondary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Nouveau produit")])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Commentaire","label-for":"Commentaire"}},[_c('b-form-textarea',{attrs:{"id":"textarea-rows","placeholder":"Commentaire","rows":"1"},model:{value:(_vm.order.note),callback:function ($$v) {_vm.$set(_vm.order, "note", $$v)},expression:"order.note"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1 mt-3",class:{ 'disabled-cursor': _vm.isLoading || !_vm.isOrderValid },attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading || !_vm.isOrderValid},on:{"click":function($event){$event.preventDefault();return _vm.addOrder.apply(null, arguments)}}},[_c('b-icon',{attrs:{"icon":"plus","aria-hidden":"true"}}),_vm._v(" Ajouter ")],1),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.clearForm.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"arrow-counterclockwise"}}),_vm._v(" Réinitialiser ")],1)],1)],1)],1)])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }