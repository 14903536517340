<template>
  <div v-if="user">
    <b-form @submit.prevent="searchCustomer">
      <b-form-group
        label="Chercher client par numéro de téléphone"
        label-for="phone-input"
        :state="phoneNumber.length === 8 ? true : false"
        :invalid-feedback="phoneNumber.length < 8 ? 'Le numéro de téléphone doit contenir 8 chiffres' : ''"
      >
        <b-input-group>
          <b-form-input
            v-model.trim="phoneNumber"
            type="tel"
            placeholder="Entrez le numéro de téléphone"
            maxlength="8"
            minlength="8"
          />
          <b-input-group-append>
            <b-button
              variant="outline-secondary"
              type="submit"
              :disabled="phoneNumber.length !== 8"
            >
              <b-icon icon="search" />
              Chercher client
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>

    <!-- <b-card
      v-if="showStats"
      title="Customer Stats"
    >
      <b-row>
        <b-col md="4">
          <b-card
            title="Total colis effectué par le client"
            class="text-center"
          >
            <h2>{{ totalOrders }}</h2>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            title="Total colis en cours"
            class="text-center"
          >
            <h2>{{ pendingOrders }}</h2>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            title="Total colis livré"
            class="text-center"
          >
            <h2>{{ totalDeliveredOrders }}</h2>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-card
            title="Total colis retour"
            class="text-center"
          >
            <h2>{{ totalReturnedOrders }}</h2>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            title="Pourcentage de réussite"
            class="text-center"
          >
            <h2>{{ successPercentage }}%</h2>
          </b-card>
        </b-col>
      </b-row>
    </b-card> -->

    <b-card-code title="Créer un nouveau colis">
      <b-card-text>
        <span>Veuillez saisir les coordonnées de colis</span>
      </b-card-text>
      <div
        v-if="isLoading === true"
        class="text-center mb-2"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Nom du client"
                label-for="nom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Gouvernorat"
                label-for="gouvernorat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="gouvernorat"
                  rules="required"
                >
                  <v-select
                    v-model="order.governorate"
                    :clearable="false"
                    placeholder="Choisissez une gouvernorat"
                    label="name"
                    :options="governorates"
                    @input="getDelegations()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Délégation"
                label-for="delegation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="delegation"
                  rules="required"
                >
                  <v-select
                    v-model="order.delegation"
                    :clearable="false"
                    placeholder="Choisissez une délégation"
                    label="name"
                    :options="delegations"
                    :disabled="!order.governorate"
                    @input="getLocalities()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Localité"
                label-for="localité"
              >
                <validation-provider
                  #default="{ errors }"
                  name="localité"
                >
                  <v-select
                    v-model="order.locality"
                    :clearable="false"
                    placeholder="Choisissez une localité"
                    label="name"
                    :options="localities"
                    :disabled="!order.governorate || !order.delegation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Adresse complète"
                label-for="adresse"
              >
                <validation-provider
                  #default="{ errors }"
                  name="adresse"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Adresse complètes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Téléphone"
                label-for="Téléphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Téléphone"
                  rules="required|integer|length:8"
                >
                  <b-form-input
                    v-model="order.phone1"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone"
                    maxlength="8"
                    type="tel"
                    :formatter="formatPhone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Téléphone 2"
                label-for="Téléphone2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone2"
                  rules="integer|length:8"
                >
                  <b-form-input
                    v-model="order.phone2"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone 2"
                    maxlength="8"
                    type="tel"
                    :formatter="formatPhone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Montant total inclut la livraison"
                label-for="montant ttc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="montant ttc"
                  rules="required"
                >
                  <b-form-input
                    v-model="order.price_ttc"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Montant TTC"
                    type="number"
                    step="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="user.weight_price === true"
              md="4"
            >
              <b-form-group
                label="Poids volumétrique de colis"
                label-for="Poids volumétrique de colis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Poids de colis"
                  rules="required"
                >
                  <v-select
                    v-model="order.weight"
                    :clearable="false"
                    placeholder="Poids volumétrique de colis"
                    label="weight"
                    :options="user.weights"
                    @input="fetch_price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Mode de paiement"
                label-for="type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <v-select
                    v-model="order.payment_method"
                    :clearable="false"
                    placeholder="Mode de paiement"
                    label="value"
                    :options="types"
                    :reduce="type => type.key"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="demo-inline-spacing"
            >
              <b-form-checkbox
                v-model="order.is_an_exchange"
                name="check-button"
                switch
                inline
              >
                Échange
              </b-form-checkbox>
              <b-form-checkbox
                v-model="order.is_fragile"
                name="check-button"
                switch
                inline
              >
                Fragile
              </b-form-checkbox>
              <b-form-checkbox
                v-model="order.is_discharge"
                name="check-button"
                switch
                inline
              >
                Décharge
              </b-form-checkbox>
            </b-col>
          </b-row>
          <br>
          <b-col v-if="order.is_an_exchange">
            <b-form-group
              label="Article à échanger"
              label-for="exchange_article"
            >
              <validation-provider
                v-slot="{ errors }"
                name="exchange_article"
              >
                <b-form-input
                  id="exchange_article"
                  v-model="order.exchange_article"
                  placeholder="Article à échanger"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="order.is_an_exchange">
            <b-form-group
              label="Quantité à échanger"
              label-for="exchange_quantity"
            >
              <validation-provider
                v-slot="{ errors }"
                name="exchange_quantity"
              >
                <b-form-input
                  id="exchange_quantity"
                  v-model="order.exchange_quantity"
                  placeholder="Quantité à échanger"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-card-code title="Produits">
            <b-card-text>
              <span>Veuillez saisir les coordonnées des produits </span>
            </b-card-text>
            <!-- form -->
            <validation-observer ref="simpleRules">
              <div>
                <div>
                  <b-form
                    ref="form"
                    :style="{ height: trHeight }"
                    class="repeater-form"
                    @submit.prevent="repeateAgain"
                  >
                    <!-- Row Loop -->
                    <b-row
                      v-for="(item, index) in items"
                      :id="item.id"
                      :key="item.id"
                      ref="row"
                    >
                      <!-- produit -->
                      <b-col md="6">
                        <b-form-group
                          label="Produit"
                          label-for="produit"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="products"
                            rules="required"
                          >
                            <b-form-input
                              v-model="item.product"
                              placeholder="produit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Quantity -->
                      <b-col md="3">
                        <b-form-group
                          label="Quantité"
                          label-for="quantity"
                        >
                          <b-form-input
                            id="quantity"
                            v-model="item.quantity"
                            type="number"
                            placeholder="Quantity"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="6"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>Supprimer</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mb-2"
                  variant="outline-secondary"
                  :style="{
                    backgroundColor: '#F4E7E7 !important',
                    borderColor: '#F4E7E7 !important',
                    color: '#ea5455 !important'
                  }"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Nouveau produit</span>
                </b-button>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Commentaire"
                      label-for="Commentaire"
                    >
                      <b-form-textarea
                        id="textarea-rows"
                        v-model="order.note"
                        placeholder="Commentaire"
                        rows="1"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-3"
                      :class="{ 'disabled-cursor': isLoading || !isOrderValid }"
                      :disabled="isLoading || !isOrderValid"
                      @click.prevent="addOrder"
                    >
                      <b-icon
                        icon="plus"
                        aria-hidden="true"
                      />
                      Ajouter
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      type="reset"
                      class="mt-3"
                      @click.prevent="clearForm"
                    >
                      <b-icon
                        icon="arrow-counterclockwise"
                        class="mr-2"
                      />
                      Réinitialiser
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </validation-observer>
          </b-card-code>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import storeAuth from '@/store/store'

/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCardText /*  */,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'CreateOrder',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        {
          product: '',
          quantity: 1,
        },
      ],
      itemsOptions: [],
      nextTodoId: 1,
      types: [
        {
          key: 0,
          value: 'Espèce',
        },
        {
          key: 1,
          value: 'par chèque',
        },
      ],
      governorates: [],
      delegations: [],
      localities: [],
      order: {
        payment_method: 0,
        name: '',
        address: '',
        phone1: '',
        phone2: '',
        note: '',
        price_ttc: '',
        price_delivery: '',
        governorate: '',
        delegation: '',
        exchange_article: '',
        exchange_quantity: '',
        locality: null,
        is_an_exchange: false,
        is_fragile: false,
        is_discharge: false,
        total_boxes: 1,
      },
      required,
      user: null,
      isLoading: false,
      isHasManyBoxes: false,
      phoneNumber: '',
      customer: null,
      totalOrders: null,
      pendingOrders: null,
      totalDeliveredOrders: null,
      totalReturnedOrders: null,
      successPercentage: null,
      showStats: false,
    }
  },
  computed: {
    isOrderValid() {
      return (
        this.order.name !== ''
        && this.order.phone1 !== ''
        && this.order.price_ttc !== ''
        && this.order.governorate !== ''
        // && this.order.delegation !== ''
      )
    },
  },

  created() {
    this.getUser()
    this.getGovernorates()
  },
  methods: {
    async fetch_price() {
      const result = this.user.prices.find(element => element.weight.id === this.order.weight.id)
      this.order.price_delivery = result.delivery_price
      this.order.price_return = result.return_price
    },
    async getUser() {
      const response = await axios.get('api/v1/users/me/')
      storeAuth.commit('setUser',
        response.data)
      this.user = response.data
      this.order.price_delivery = this.user.price_delivery
      this.order.price_return = this.user.price_return
    },
    async getGovernorates() {
      const { data } = await axios.get('/api/governorates/')
      this.governorates = data
    },
    async getDelegations() {
      this.order.delegation = ''
      const { data } = await axios.get('/api/delegations/', { params: { governorate: this.order.governorate.id } })
      this.delegations = data
    },
    async getLocalities() {
      this.order.locality = ''
      const { data } = await axios.get('/api/localities/', { params: { delegation: this.order.delegation.id } })
      this.localities = data
    },
    repeateAgain() {
      this.items.push({
        product: '',
        quantity: 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    async addOrder() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          let weight = null
          if (this.user.weight_price === true) {
            weight = this.order.weight.id
          }

          try {
            this.isLoading = true
            await axios
              .post(
                '/api/orders/create/', {
                  name: this.order.name,
                  address: this.order.address,
                  phone1: this.order.phone1,
                  phone2: this.order.phone2,
                  note: this.order.note,
                  price_ttc: this.order.price_ttc,
                  price_delivery: this.order.price_delivery,
                  price_return: this.order.price_return,
                  governorate: this.order.governorate.id,
                  delegation: this.order.delegation.id,
                  locality: this.order.locality.id,
                  products: this.items,
                  is_an_exchange: this.order.is_an_exchange,
                  is_fragile: this.order.is_fragile,
                  is_discharge: this.order.is_discharge,
                  exchange_article: this.order.exchange_article,
                  exchange_quantity: this.order.exchange_quantity,
                  payment_method: this.order.payment_method,
                  weight,
                },
              )
            this.clearForm()
            this.showToast('success', 'top-center', 'Colis Ajouté avec avec succès')
            window.scrollTo(0, 0)
            this.isLoading = false
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000) // expected output: ReferenceError: nonExistentFunction is not defined
            this.isLoading = false
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
    clearForm() {
      this.order = {
        payment_method: 0,
        name: '',
        address: '',
        phone1: '',
        phone2: '',
        note: '',
        price_ttc: '',
        price_delivery: '',
        price_return: '',
        governorate: '',
        delegation: '',
        exchange_quantity: '',
        exchange_article: '',
        locality: null,
        is_an_exchange: false,
        is_fragile: false,
        is_discharge: false,
        total_boxes: 1,
      }
      this.order.price_delivery = this.user.price_delivery
      this.order.price_return = this.user.price_return
      this.items = [
        {
          product: '',
          quantity: 1,
        },
      ]
      this.$refs.simpleRules.reset()
    },
    async searchCustomer() {
      if (this.phoneNumber.length === 8) {
        try {
          this.isLoading = true
          const { data } = await axios.get('/api/customers/customer-stats/', {
            params: { phone: this.phoneNumber },
          })
          if (data.customer) {
            this.customer = data.customer
            this.isLoading = false
            this.order.name = this.customer.name
            this.order.phone1 = this.customer.phone1
            this.order.phone2 = this.customer.phone2
            this.order.address = this.customer.address
            this.order.governorate = this.customer.governorate
            this.order.delegation = this.customer.delegation
            this.showStats = true
            this.totalOrders = data.total_orders
            this.pendingOrders = data.pending_orders
            this.totalDeliveredOrders = data.total_delivered_orders
            this.totalReturnedOrders = data.total_returned_orders
            this.successPercentage = data.success_percentage
            this.showToast('success', 'top-center', 'Les informations du client ont été rempli avec succès')
            this.getDelegations()
          } else {
            this.showToast('info', 'top-center', 'Aucun client trouvé')
          }
        } catch (error) {
          this.isLoading = false
          this.showToast('error', 'top-center', 'Aucun client trouvé')
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.disabled-cursor {
  cursor: not-allowed;
  opacity: 0.8;
}
</style>
